import { useNavigate } from "react-router";
import React from "react";
import {
  cleanUpRefreshToken,
  getIdp,
  isAccessAndRefreshTokenInvalid,
  isAuthTokenExist,
  isRefreshTokenExist,
  persistUserInfo,
} from "./auth";
import { PageNames } from "../types";
import { defined } from "../utils/typeHelper";
import { sso } from "../api/authApi";
import { useDispatch, useSelector } from "react-redux";
import { UserInfoActions, selectUserInfo } from "../store/userSlice";

const USER_CLOSED_DIALOG_ERROR_CODE = 12006;

export function useAuthentication() {
  const dispatch = useDispatch();
  const userInfo = useSelector(selectUserInfo);
  const [authenticating, setAuthenticating] = React.useState(true);
  const navigate = useNavigate();

  const authenticated = React.useMemo(() => {
    return isAuthTokenExist();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  const [authenticatingError, setAuthenticatingError] = React.useState<string>();
  const [refreshTokenError, setRefreshTokenError] = React.useState<string>();

  const authUrl = React.useMemo(() => {
    const url = new URL(`${PageNames.RequestAuthentication}${window.location.search}`, window.location.origin);
    return url.toString();
  }, []);

  const authenticate = React.useCallback(async (authCode: string, idp: string) => {
    try {
      setAuthenticating(true);
      const result = await sso.requestToken(defined(authCode), idp);
      if (result.success && result.data !== undefined) {
        persistUserInfo(result.data, idp);
        dispatch(UserInfoActions.updateUser(result.data));
      } else {
        setAuthenticatingError(result.error?.message || `${result.error?.code}` || "Unknown error");
      }
    } catch (ex: unknown) {
      const err = ex as Error;
      setAuthenticatingError(err.message || "Unknown error");
    } finally {
      setAuthenticating(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refresh = React.useCallback(async () => {
    try {
      setAuthenticating(true);
      const result = await sso.refreshToken();
      if (result.success && result.data !== undefined) {
        persistUserInfo(result.data, getIdp());
        dispatch(UserInfoActions.updateUser(result.data));
      } else {
        setRefreshTokenError(result.error?.message || `${result.error?.code}` || "Unknown error");
        cleanUpRefreshToken();
      }
    } catch (ex: unknown) {
      const err = ex as Error;
      setRefreshTokenError(err.message || "Unknown error");
      cleanUpRefreshToken();
    } finally {
      setAuthenticating(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasRequested = React.useRef(false);

  const requestLogin = React.useCallback(() => {
    Office.context.ui.displayDialogAsync(authUrl, { height: 50, width: 40 }, (result) => {
      if (result.status === Office.AsyncResultStatus.Failed) {
        navigate("/");
        return;
      }
      const dialog = result.value;
      dialog.addEventHandler(Office.EventType.DialogMessageReceived, (arg) => {
        const input = arg as { message: string | undefined };

        if (input.message !== undefined) {
          const message = JSON.parse(input.message) as AuthenticateMessageTypes;
          if (message.type === "x-entrilia-auth") {
            authenticate(message.code, message.idp);
            dialog.close();
          } else if (message.type === "x-entrilia-auth-error") {
            setAuthenticatingError(message.error);
            dialog.close();
          }
        }
      });
      dialog.addEventHandler(Office.EventType.DialogEventReceived, (arg) => {
        const response = arg as { error: number };
        if (response.error === USER_CLOSED_DIALOG_ERROR_CODE) {
          navigate("/");
        }
      });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUrl, authenticate]);

  React.useEffect(() => {
    if (hasRequested.current) return;
    hasRequested.current = true;

    const authRequired = isAccessAndRefreshTokenInvalid();
    const refreshTokenExist = isRefreshTokenExist();
    if (authRequired) {
      requestLogin();
    } else if (refreshTokenExist) {
      refresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { authenticating, authenticated, authenticatingError, refreshTokenError };
}

export type AuthenticateMessage = {
  type: "x-entrilia-auth";
  code: string;
  idp: string;
};

export type AuthenticateMessageError = {
  type: "x-entrilia-auth-error";
  error: string;
};

export type AuthenticateMessageTypes = AuthenticateMessage | AuthenticateMessageError;
