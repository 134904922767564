import { XRow } from "../hooks/useExcelDataApi.types";

/*
Max Allowed Payload Size (symbol length): 4200000 symbols


Row height handling example:
    rows: 11970
    api calls: 11970 * 3 = 35910
    total payload: 11657201
    ONE ACTION payload: 11657201 / 35910 = 325
    ONE ROW payload: 325 * 3 = 975

    expected rows to be handled in one batch: 4200000 / 975 = 4307 - 20% = 3446
*/
export async function loadRowsHeight(context: Excel.RequestContext, sheet: Excel.Worksheet, rows: XRow[]) {
  let hasSynced = true;
  for (let index = 0; index < rows.length; index++) {
    const row = rows[index];
    if (row === undefined) {
      continue;
    }
    hasSynced = false;
    const range = sheet.getRangeByIndexes(index, 0, 1, 1).getEntireRow();
    if (row.bestFit) {
      range.format.autofitRows();
    } else if (row.height !== undefined) {
      range.format.rowHeight = row.height;
    }
    // see function comment to understand why we sync context every 3446 rows
    if (index > 0 && index % 3446 === 0) {
      // eslint-disable-next-line office-addins/no-context-sync-in-loop
      await context.sync();
      context.application.suspendApiCalculationUntilNextSync();
      context.application.suspendScreenUpdatingUntilNextSync();
      hasSynced = true;
    }
  }

  if (!hasSynced) {
    await context.sync();
  }
}
