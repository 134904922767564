import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";
import { Button, IconButton, Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { ReportAccessState } from "../../api/biApi.types";
import { useReportServiceContext } from "../../contexts/ReportServiceContext";
import CheckCircle from "../../icons/CheckCircle";
import { removeReportProcessing } from "../../store/reportsSlice";
import { StepEnum, XEntriliaReportDescriptor, XEntriliaReportPrecessing } from "../../store/store.types";
import { PageNames } from "../../types";
import StopLoadingReportButton from "./StopLoadingReportButton";

interface Props {
  report: XEntriliaReportDescriptor;
  processingReport: XEntriliaReportPrecessing | undefined;
  reportAccessState: ReportAccessState | undefined;
}

const WorkbookReportItemContentActionButton = ({ processingReport, report, reportAccessState }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { refreshReport, cancelRequest } = useReportServiceContext();
  const navigateToEditReport = () => navigate(`${PageNames.Report}/${report.id}`);

  const handleTryAgain = () => {
    if (report !== undefined) {
      refreshReport(report.id);
    }
  };

  const showRefreshButton = !processingReport?.error && reportAccessState?.isConfigurationValid === false;
  const showStopLoadingButton = processingReport?.loading === true && !(processingReport?.step === StepEnum.Done);
  const isRefreshJustCompleted = processingReport?.step === StepEnum.Done;

  if (processingReport?.error) {
    return (
      <Button
        size="small"
        color="error"
        variant="text"
        sx={{ fontSize: "12px" }}
        onClick={(evt) => {
          evt.stopPropagation();
          handleTryAgain();
        }}
      >
        Try again
      </Button>
    );
  }

  if (showRefreshButton) {
    return (
      <Tooltip title="Refresh with new filters" arrow disableInteractive>
        <IconButton size="small" sx={{ p: 0.4 }} onClick={navigateToEditReport}>
          <RefreshRoundedIcon />
        </IconButton>
      </Tooltip>
    );
  }

  if (showStopLoadingButton) {
    return (
      <StopLoadingReportButton
        cancellable={processingReport?.cancellable === true}
        onClick={() => {
          cancelRequest(report.reportCode, report.worksheetId);
          dispatch(removeReportProcessing(report.id));
        }}
      />
    );
  }

  if (isRefreshJustCompleted) {
    return (
      <IconButton
        size="small"
        sx={{ p: 0.4, mr: 1 }}
        onMouseEnter={() => dispatch(removeReportProcessing(processingReport.id))}
      >
        <CheckCircle />
      </IconButton>
    );
  }

  return null;
};

export default WorkbookReportItemContentActionButton;
