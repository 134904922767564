import { Box, FormControl, ListItemIcon, ListItemText, MenuItem, Select, Stack, Typography } from "@mui/material";
import ClientIcon from "../selectClient/ClientIcon";
import { getActualClients } from "../selectClient/getActualClients";
import { useMemo, useState } from "react";
import { ClientInfo, UserPermissionSet } from "../../api/biApi.types";

interface Props {
  defaultClientCode: string | undefined;
  permissions: UserPermissionSet[];
  clients: ClientInfo[];
  isLoading: boolean;
  onCompanyChange: (company: string) => void;
}

const CompanySelection = ({ onCompanyChange, defaultClientCode, clients, permissions, isLoading }: Props) => {
  const [clientCode, setClientCode] = useState<string>(() => defaultClientCode || "");
  const companies = useMemo(() => getActualClients(permissions, clients), [clients, permissions]);

  return (
    <Stack spacing={1}>
      <Typography variant="subtitle1">Company</Typography>
      <FormControl>
        <Select
          autoFocus={defaultClientCode === undefined}
          size="small"
          value={clientCode}
          onChange={(e) => e.target.value}
          displayEmpty
          renderValue={(code) => {
            const company = companies.find((c) => c.clientCode === code);
            if (company) {
              return (
                <Box display={"flex"} alignItems={"center"} gap={1} maxHeight={20}>
                  <ClientIcon logoUrl={company?.branding.logoMarkUrl} />
                  <Typography fontSize={13} noWrap>
                    {company?.title}
                  </Typography>
                </Box>
              );
            }

            return (
              <Typography fontSize={13} color="textSecondary">
                Select a company
              </Typography>
            );
          }}
          disabled={isLoading}
        >
          {companies.map((c) => (
            <MenuItem
              key={c.clientCode}
              value={c.clientCode}
              onClick={() => {
                setClientCode(c.clientCode);
                onCompanyChange(c.clientCode);
              }}
            >
              <ListItemIcon>
                <ClientIcon logoUrl={c.branding.logoMarkUrl} />
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ fontSize: 13, noWrap: true }}>{c.title}</ListItemText>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};

export default CompanySelection;
