import { Alert, Link } from "@mui/material";

interface Props {
  onClose: () => void;
}
const CustomNumberFormatsCapacityLimitReachedAlert = ({ onClose }: Props) => {
  return (
    <Alert severity="warning" sx={{ mb: 2, width: "100%" }} onClose={onClose}>
      Cell value formats may differ from original reports due to Excel's limitations.{" "}
      <Link
        href="https://help.entrilia.com/en/articles/10426576-custom-number-format-limitation-using-entrilia-for-excel-add-in"
        target="_blank"
        rel="noopener noreferrer"
        underline="always"
      >
        Learn more
      </Link>
    </Alert>
  );
};

export default CustomNumberFormatsCapacityLimitReachedAlert;
