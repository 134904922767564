import { Grid, Typography } from "@mui/material";
import React from "react";
import { FilterModelType, ReportSource } from "../../../api/biApi.types";
import { orange } from "@mui/material/colors";
import { getUpdatedAtText } from "./conditionBlock.helper";
import { XEntriliaReportParameter, XEntriliaReportDescriptor } from "../../../store/store.types";

interface ConditionBlockProps {
  filters: XEntriliaReportParameter[];
  report: XEntriliaReportDescriptor;
}

function ConditionBlock({ filters, report }: ConditionBlockProps) {
  const formatParamValues = React.useCallback((filter: XEntriliaReportParameter) => {
    return filter.text || filter.values.join(", ");
  }, []);

  return (
    <Grid container flexDirection={"column"}>
      <Typography variant="subtitle2" sx={{ mt: 1, mb: 0.1 }}>
        Conditions
      </Typography>
      {filters.map((filter) => (
        <Grid item container key={filter.description + filter.name}>
          <Grid item xs={5}>
            <Typography variant="caption" color="secondary">
              {getFilterName(filter)}
              {filter.equalityType === FilterModelType.NotEqual && "(<>)"}
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography
              variant="caption"
              sx={{
                color: (theme) =>
                  filter.equalityType === FilterModelType.NotEqual ? orange[800] : theme.palette.text.primary,
              }}
            >
              {formatParamValues(filter)}
            </Typography>
          </Grid>
        </Grid>
      ))}
      <Grid item sx={{ pt: 0.5 }}>
        <Typography variant="caption" color="secondary" sx={{ fontSize: 10 }}>
          {getUpdatedAtText(report.changedAt, report.changedBy)}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default ConditionBlock;

function getFilterName(filter: XEntriliaReportParameter) {
  return filter.reportSource === ReportSource.BI ? filter.description : filter.description || filter.name;
}
