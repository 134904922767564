import { Grid, LinearProgress, Typography } from "@mui/material";
import React from "react";
import { StepEnum, XEntriliaReportPrecessing } from "../../store/store.types";

interface Props {
  processingReport: XEntriliaReportPrecessing;
}

const loadingStepsMap: ReadonlyMap<StepEnum | undefined, number> = new Map([
  [StepEnum.Pending, 0],
  [StepEnum.RequestReportToGenerate, 25],
  [StepEnum.WaitingReportToBeGenerated, 50],
  [StepEnum.FetchGeneratedReport, 75],
  [StepEnum.PopulateReportToWorksheet, 100],
  [StepEnum.Done, 100],
]);

function getLoadingStepValue(step: StepEnum | undefined): number {
  return loadingStepsMap.get(step) || 0;
}

export default function WorkbookReportItemLoadingContent({ processingReport }: Props) {
  const [progress, setProgress] = React.useState(0);

  const isRefreshCompleted = processingReport.step === StepEnum.Done;

  React.useEffect(() => {
    if (processingReport.loading) {
      setProgress(getLoadingStepValue(processingReport.step));
    }
  }, [processingReport]);

  if (isRefreshCompleted) {
    return <Typography sx={(theme) => ({ fontSize: "10px", color: theme.palette.primary.main })}>Completed</Typography>;
  }

  return (
    <>
      <Grid container sx={{ flex: 1, flexDirection: "row", alignItems: "center", gap: 2 }}>
        <LinearProgress variant="determinate" value={progress} sx={{ flex: 1, borderRadius: "3px" }} />
        <Typography variant="caption" sx={{ color: (theme) => theme.palette.text.secondary }}>
          {processingReport.step}/4
        </Typography>
      </Grid>
      <Typography sx={(theme) => ({ fontSize: "10px", color: theme.palette.text.secondary })} noWrap>
        Step {processingReport.step} - {processingReport.stepDescription}
      </Typography>
    </>
  );
}
