import { createSelector, createSlice } from "@reduxjs/toolkit";
import { ReportAccessState, UserData } from "../api/biApi.types";
import { getUserInfo } from "../auth/auth";
import { hasPermissionsForCompany } from "../pages/helpers/accessUtils";
import { RootState, UserConfiguration, UserInfo, XEntriliaReportDescriptor } from "./store.types";

const initialState: UserConfiguration = {
  user: getUserInfo(),
  userDataLoaded: false,
  expandGroupsState: {},
  lastSelectedReportListTabIdx: 0,
  storedReportsAccessState: [],
};

const userSlice = createSlice({
  name: "userConfiguration",
  initialState,
  reducers: {
    updateUser(state, action: { payload: UserInfo | undefined }) {
      state.user = action.payload;
    },
    updateUserData(state, action: { payload: UserData }) {
      state.userData = action.payload;
      state.userDataLoaded = true;
    },
    clearUser(state) {
      state.user = undefined;
      state.userData = undefined;
      state.userDataLoaded = false;
    },
    setSelectedCompany(state, action: { payload: string }) {
      state.selectedCompany = action.payload;
    },
    setLastSelectedReportListTab(state, action: { payload: number }) {
      state.lastSelectedReportListTabIdx = action.payload;
    },
    setExpandGroupsState(state, action: { payload: { groupId: string; expanded: boolean } }) {
      const clientCode = state.selectedCompany;
      if (!clientCode) {
        return;
      }
      state.expandGroupsState = {
        ...state.expandGroupsState,
        [clientCode]: {
          ...state.expandGroupsState[clientCode],
          [action.payload.groupId]: action.payload.expanded,
        },
      };
    },
    setAccessibleReports(
      state,
      action: { payload: { access: ReportAccessState[]; storedReports: XEntriliaReportDescriptor[] } }
    ) {
      const { access, storedReports } = action.payload;
      state.storedReportsAccessState = storedReports
        .map((sr) => {
          const reportAccess = access.find((a) => a.reportCode === sr.reportCode);
          if (!reportAccess) {
            return undefined;
          }
          return { ...reportAccess, reportCode: sr.reportCode, storedReportId: sr.id };
        })
        .filter((r) => r) as ReportAccessState[];
    },
    addOrUpdateAccessibleReport(state, action: { payload: { access: ReportAccessState; storedReportId: string } }) {
      const { access, storedReportId } = action.payload;
      const existing = state.storedReportsAccessState?.find((r) => r.storedReportId === storedReportId);
      if (existing) {
        Object.assign(existing, access);
      } else {
        state.storedReportsAccessState = [...(state.storedReportsAccessState || []), { ...access, storedReportId }];
      }
    },
    addDuplicatedAccessibleReport(state, action: { payload: { reportCode: string; storedReportId: string } }) {
      const { reportCode, storedReportId } = action.payload;
      const original = state.storedReportsAccessState?.find(
        (r) => r.reportCode === reportCode && r.storedReportId !== storedReportId
      );
      if (original) {
        state.storedReportsAccessState.push({ ...original, storedReportId });
      }
    },
    deleteAccessibleReport(state, action: { payload: { storedReportId: string } }) {
      const { storedReportId } = action.payload;
      state.storedReportsAccessState = state.storedReportsAccessState.filter(
        (r) => r.storedReportId !== storedReportId
      );
    },
  },
});

export const UserInfoActions = userSlice.actions;

export const selectUserInfo = (state: RootState) => state.userInfo.user;
export const selectUserData = (state: RootState) => state.userInfo.userData;
export const selectClients = (state: RootState) => state.userInfo.userData?.clients || [];
export const selectReportsAccessState = (state: RootState) => state.userInfo.storedReportsAccessState;
export const selectReportAccessState = createSelector(
  [(state: RootState) => state.userInfo.storedReportsAccessState, (_, reportId: string) => reportId],
  (reportsAccessState, reportId) => reportsAccessState.find((r) => r.storedReportId === reportId)
);

export const selectClientsWithAccess = createSelector(selectUserData, (userData) => {
  const clients = userData?.clients || [];
  const permissions = userData?.permissions || [];

  return clients.filter((c) =>
    permissions.find((p) => p.clientCode === c.clientCode && hasPermissionsForCompany(c.clientCode, permissions))
  );
});

export const selectUserDataLoaded = (state: RootState) => state.userInfo.userDataLoaded === true;
export const selectUserPermissions = createSelector(selectUserData, (userData) => userData?.permissions || []);
export const selectSelectedCompany = (state: RootState) => state.userInfo.selectedCompany;
export const selectLastSelectedReportListTab = (state: RootState) => state.userInfo.lastSelectedReportListTabIdx;
export const selectCompanyExpandGroupsState = createSelector(
  (state: RootState) => state,
  (state) => state.userInfo.expandGroupsState[state.userInfo.selectedCompany || ""] || {}
);

export default userSlice.reducer;
