import { PropsWithChildren, useCallback, useEffect, useState } from "react";

const isPanelVisibleKey = "isDevPanelVisible";

export default function DevPanel({ children }: PropsWithChildren<Record<never, never>>) {
  const [isDevPanelVisible, setIsDevPanelVisible] = useState(() => {
    const savedState = localStorage.getItem(isPanelVisibleKey);
    return savedState === "true";
  });

  const handleKeyPress = useCallback((event: KeyboardEvent) => {
    if (event.ctrlKey && event.altKey && event.key === "F1") {
      setIsDevPanelVisible((prev) => {
        const newState = !prev;
        localStorage.setItem(isPanelVisibleKey, newState.toString());
        return newState;
      });
    }
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{isDevPanelVisible && children}</>;
}
