import { Button } from "@mui/material";
import React from "react";
import { logout } from "../../../auth/auth";
import { UserInfoActions } from "../../../store/userSlice";
import { PageNames } from "../../../types";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { RedirectKeys } from "../../RedirectKeys";

export default function LoginToAnotherAccountButton() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLoginToAnotherAccount = React.useCallback(async () => {
    await logout();
    dispatch(UserInfoActions.clearUser());
    navigate(`/${PageNames.DoSignIn}?${RedirectKeys.Prompt}=select_account`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Button
      variant="outlined"
      size="medium"
      sx={(theme) => ({ width: theme.spacing(25), mt: 1.5 })}
      onClick={handleLoginToAnotherAccount}
    >
      Login to another account
    </Button>
  );
}
