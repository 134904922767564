import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { selectReports } from "../../store/reportsSlice";
import { PageNames } from "../../types";
import DevTools from "../components/DevTools";
import DevPanel from "../components/common/DevPanel";
import { Footer } from "./Footer";
import { NoReports } from "./reports/NoReports";
import Reports from "./reports/Reports";

export default function HomePage() {
  const reports = useSelector(selectReports);
  const navigate = useNavigate();
  const navigateToAddReport = () => navigate(PageNames.Report);

  return (
    <Grid container sx={{ flex: 1, flexDirection: "column" }}>
      <Grid
        container
        sx={{
          flex: 1,
          flexDirection: "column",
          gap: 0.5,
          px: 2,
          py: 1,
          position: "relative",
        }}
      >
        {reports.length === 0 && <NoReports onAddReport={navigateToAddReport} />}
        {reports.length > 0 && <Reports />}
        <DevPanel>
          <DevTools />
        </DevPanel>
      </Grid>
      <Footer />
    </Grid>
  );
}
