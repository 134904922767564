import CachedRoundedIcon from "@mui/icons-material/CachedRounded";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import { ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { XEntriliaReportDescriptor, XEntriliaReportPrecessing } from "../../store/store.types";
import { PageNames } from "../../types";

interface Props {
  anchorEl: HTMLElement | null;
  activeReport: XEntriliaReportDescriptor;
  isReportDeleted: boolean;
  processingReport: XEntriliaReportPrecessing | undefined;
  handleRefreshReport: (reportId: string) => void;
  handleDeleteReport: (report: XEntriliaReportDescriptor) => void;
  onClose: () => void;
}

const WorkbookReportItemContentMenu = ({
  anchorEl,
  activeReport,
  handleRefreshReport,
  handleDeleteReport,
  onClose,
  isReportDeleted,
  processingReport,
}: Props) => {
  const navigate = useNavigate();
  const navigateToEditReport = () => navigate(`${PageNames.Report}/${activeReport?.id}`);
  const canRefreshCurrentReport =
    processingReport === undefined || !processingReport.loading || !!processingReport.error;

  return (
    <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={onClose}>
      {!isReportDeleted && [
        <MenuItem
          key="quick-refresh"
          sx={{ minHeight: "auto" }}
          disabled={!canRefreshCurrentReport}
          onClick={() => {
            handleRefreshReport(activeReport.id);
            onClose();
          }}
        >
          <ListItemIcon>
            <CachedRoundedIcon fontSize="small" sx={(theme) => ({ color: theme.palette.secondary.light })} />
          </ListItemIcon>
          <Typography variant="body2" sx={{ color: (theme) => theme.palette.text.primary }}>
            Quick Refresh
          </Typography>
        </MenuItem>,
        <MenuItem
          key="refresh-with-filters"
          sx={{ minHeight: "auto" }}
          disabled={!canRefreshCurrentReport}
          onClick={navigateToEditReport}
        >
          <ListItemIcon>
            <TuneRoundedIcon fontSize="small" sx={(theme) => ({ color: theme.palette.secondary.light })} />
          </ListItemIcon>
          <Typography variant="body2" sx={{ color: (theme) => theme.palette.text.primary }}>
            Refresh with new Filters
          </Typography>
        </MenuItem>,
      ]}
      <MenuItem
        sx={{ minHeight: "auto" }}
        onClick={() => {
          handleDeleteReport(activeReport);
          onClose();
        }}
      >
        <ListItemIcon>
          <DeleteOutlineIcon fontSize="small" color="error" />
        </ListItemIcon>
        <Typography variant="body2" color="error">
          Delete Report
        </Typography>
      </MenuItem>
    </Menu>
  );
};

export default WorkbookReportItemContentMenu;
