import { Grid, Typography } from "@mui/material";

interface Props {
  name: string;
  title: string;
}

const InfoBlockItem = ({ name, title }: Props) => {
  return (
    <Grid item container>
      <Grid item xs={5}>
        <Typography variant="caption" color="secondary">
          {name}
        </Typography>
      </Grid>
      <Grid item xs={7}>
        <Typography variant="caption" sx={{ color: "text.primary" }}>
          {title}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default InfoBlockItem;
