import { logError } from "../../../logging";
import { XWorksheetData } from "../hooks/useExcelDataApi.types";
import { columnsAutofit, loadColumnsWidth } from "./loadColumns";
import { loadGroupsAsync } from "./loadGroups";
import { loadImages } from "./loadImages";
import { loadRows } from "./loadRows";
import { loadRowsHeight } from "./loadRowsHeight";
import mergeCells from "./mergeCells";

export default async function populateWorksheet(
  context: Excel.RequestContext,
  worksheetData: XWorksheetData,
  worksheetId: string,
  onSyncCustomNumberFormatError: () => void
) {
  let eventBoolean = false;
  try {
    context.runtime.load("enableEvents");
    await context.sync();

    eventBoolean = context.runtime.enableEvents;
    context.runtime.enableEvents = !eventBoolean;

    const sheet = context.workbook.worksheets.getItem(worksheetId);
    context.application.suspendApiCalculationUntilNextSync();
    context.application.suspendScreenUpdatingUntilNextSync();

    await mergeCells(context, sheet, worksheetData.mergeCells);
    await loadColumnsWidth(context, sheet, worksheetData.columns);
    await loadRowsHeight(context, sheet, worksheetData.rows);
    await loadGroupsAsync(context, sheet, worksheetData.groups);
    await loadRows(context, sheet, worksheetData.rows, () => onSyncCustomNumberFormatError());
    await columnsAutofit(context, sheet, worksheetData.columns);
    await loadImages(context, sheet, worksheetData.images);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error({ error });
    logError({ error }, "Error populating worksheet");
  } finally {
    context.runtime.enableEvents = eventBoolean;
  }
}
