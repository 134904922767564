import { ExcelReport, ReportKind, ReportSource } from "../../api/biApi.types";
import { ReportsGroup } from "./AvailableReportGroup";

export const MasterDataLevelCaption = "Master Data";

const findOrCreateGroup = (
  result: ReportsGroup[],
  groupName: string,
  reportKind: ReportKind,
  reportSource: ReportSource,
  levelOrder: number
): ReportsGroup => {
  let group = result.find(
    (g) => g.caption === groupName && g.reportSource === reportSource && g.reportKind === reportKind
  );

  if (!group) {
    group = {
      caption: groupName,
      reports: [],
      reportKind,
      reportSource,
      levelOrder: levelOrder,
      id: getUniqueGroupId(groupName, reportKind, reportSource),
    };
    result.push(group);
  }

  return group;
};

export const groupReports = (reports: ExcelReport[]) => {
  const result: ReportsGroup[] = [];
  reports.forEach((ar) => {
    const groupName = ar.reportKind === ReportKind.MasterDataReport ? MasterDataLevelCaption : ar.level;
    const levelOrder = ar.reportKind === ReportKind.MasterDataReport ? 0 : ar.levelOrder;
    const group = findOrCreateGroup(result, groupName, ar.reportKind, ar.reportSource, levelOrder);
    group.reports.push(ar);
  });

  // Sort reports within each group
  result.forEach((group) => {
    if (group.reportSource === ReportSource.BC && group.reportKind === ReportKind.Report) {
      group.reports.sort((a, b) => a.order - b.order);
      return;
    }
    if (group.reportSource === ReportSource.BI && group.reportKind === ReportKind.Report) {
      group.reports.sort((a, b) => a.name.localeCompare(b.name));
      return;
    }
    if (group.reportKind === ReportKind.MasterDataReport) {
      group.reports.sort((a, b) => a.name.localeCompare(b.name));
    }
  });

  // Sort groups
  result.sort((a, b) => {
    if (a.reportKind === ReportKind.MasterDataReport) {
      return 1; // Ensure MasterDataReport is last
    }
    if (b.reportKind === ReportKind.MasterDataReport) {
      return -1;
    }
    if (a.reportSource === ReportSource.BC && b.reportSource === ReportSource.BI) {
      return -1;
    }
    if (a.reportSource === ReportSource.BI && b.reportSource === ReportSource.BC) {
      return 1;
    }
    return a.levelOrder - b.levelOrder;
  });

  adjustDuplicatedLevelNames(result);

  return result;
};

const adjustDuplicatedLevelNames = (reports: ReportsGroup[]) => {
  const levelNames = reports.map((r) => r.caption);
  const duplicatedLevelNames = levelNames.filter((name, index) => levelNames.indexOf(name) !== index);
  reports.forEach((r) => {
    if (duplicatedLevelNames.includes(r.caption)) {
      r.caption = getDuplicatedCaption(r);
    }
  });
};

export const getUniqueGroupId = (name: string, reportKind: ReportKind, reportSource: ReportSource) => {
  if (reportKind === ReportKind.MasterDataReport) {
    return MasterDataLevelCaption;
  }

  return `${name} (${getGroupNameSuffix(reportSource)})`;
};

const getDuplicatedCaption = (reportGroup: ReportsGroup) => {
  if (reportGroup.reportKind === ReportKind.MasterDataReport) {
    return MasterDataLevelCaption;
  }

  return `${reportGroup.caption} (${getGroupNameSuffix(reportGroup.reportSource)})`;
};

const getGroupNameSuffix = (reportSource: ReportSource) => {
  return reportSource === ReportSource.BC ? "Fund Operations" : "SmartViews";
};
