import { useDispatch, useSelector } from "react-redux";
import CustomNumberFormatsCapacityLimitReachedAlert from "../components/common/alerts/CustomNumberFormatsCapacityLimitReachedAlert";
import { hideAlert, selectShouldShowCustomNumberFormatsCapacityLimitReached } from "../../store/alertsSlice";

const AlertsSection = () => {
  const dispatch = useDispatch();
  const showCustomNumberFormatsCapacityLimitReached = useSelector(
    selectShouldShowCustomNumberFormatsCapacityLimitReached
  );

  if (showCustomNumberFormatsCapacityLimitReached) {
    return (
      <CustomNumberFormatsCapacityLimitReachedAlert
        onClose={() => dispatch(hideAlert("CustomNumberFormatsCapacityLimitReached"))}
      />
    );
  }

  return null;
};

export default AlertsSection;
