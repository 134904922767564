import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { alpha } from "@mui/material";
import { ThemeOptions } from "@mui/material/styles/createTheme";
import React from "react";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
declare module "@mui/material/styles" {
  interface TypeBackground {
    grey: string;
  }

  interface PaletteOptions {
    tooltip: {
      fill: string;
    };
    dialogIcon: string;
  }
  interface Palette {
    tooltip: {
      fill: string;
    };
    dialogIcon: string;
  }
}

export const themeOptions: ThemeOptions = {
  palette: {
    mode: "light",
    primary: {
      main: "#00A866",
      dark: "#009066",
      light: "#32CA7F",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#647381",
      dark: "#303A48",
      light: "#8E9BA9",
      contrastText: "#FFFFFF",
    },
    action: {
      active: "#8E9BA9",
      hover: "rgba(35, 52, 59, 0.04)",
      selected: "#EEEFF0",
      disabledBackground: "#F1F1F1",
      focus: "rgba(100, 115, 129, 0.12)",
      disabled: "#C9CFD6",
    },
    text: {
      primary: "#23343B",
      secondary: "#647381",
      disabled: "#B4BDC6",
    },
    info: {
      main: "#0288D1",
      dark: "#01579B",
      light: "#03A9F4",
      contrastText: "#FFFFFF",
    },
    error: {
      main: "#D32F2F",
      dark: "#C62828",
      light: "#EF5350",
      contrastText: "#FFFFFF",
    },
    warning: {
      main: "#ED6C02",
      dark: "#E65100",
      light: "#FF9800",
      contrastText: "#FFFFFF",
    },
    success: {
      main: "#2E7D32",
      dark: "#1B5E20",
      light: "#4CAF50",
      contrastText: "#FFFFFF",
    },
    background: {
      default: "#FFFFFF",
      paper: "#FFFFFF",
      grey: "#FAFAFA",
    },
    divider: "#E5E6E9",
    common: {
      black: "#000000",
      white: "#FFFFFF",
    },
    tooltip: {
      fill: "#23343B",
    },
    dialogIcon: "#C7DBE5",
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        root: ({ ownerState, theme }) => {
          const borderColor = ownerState.severity ? theme.palette[ownerState.severity].main : undefined;
          return {
            ...(borderColor &&
              ownerState.variant === "standard" && {
                border: `1px solid ${alpha(borderColor, 0.5)}`,
              }),
          };
        },
        message: ({ theme }) => ({ ...theme.typography.body1 }),
      },
      defaultProps: {
        iconMapping: {
          warning: React.createElement(WarningAmberRoundedIcon),
          success: React.createElement(CheckCircleOutlineRoundedIcon),
          error: React.createElement(ErrorOutlineRoundedIcon),
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          fontSize: 14,
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        size: "small",
        IconComponent: ArrowDropDownRoundedIcon,
      },
      styleOverrides: {
        iconOutlined: ({ theme }) => ({
          color: theme.palette.secondary.light,
        }),
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        outlinedSecondary: ({ theme }) => ({
          borderColor: theme.palette.divider,
          ":hover": {
            borderColor: theme.palette.divider,
          },
          "&.Mui-disabled": {
            borderColor: theme.palette.divider,
          },
        }),
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: "13px",
          "&.MuiOutlinedInput-input:not(.MuiInputBase-inputSizeSmall):not(.MuiAutocomplete-input)": {
            padding: "15px 14px",
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          padding: "7.5px 9px",
        },
        option: {
          minHeight: "auto !important",
          fontSize: "13px",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "13px",
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: ({ theme }) => ({
          ...theme.typography.body2,
        }),
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          minHeight: "auto",
          fontSize: "13px",
          fontWeight: "500",
          "&.Mui-selected": {
            color: theme.palette.primary.main,
            ".MuiTypography-root": {
              color: theme.palette.primary.main,
            },
          },
        }),
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: ({ theme }) => ({
          "&:last-child": {
            paddingBottom: theme.spacing(2),
          },
        }),
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderColor: theme.palette.divider,
        }),
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        overflowY: "auto",
        margin: 0,
        padding: 0,
        listStyle: "none",
        "&::-webkit-scrollbar": {
          width: "14px",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#F5F5F5",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#C7CED4",
          borderRadius: "8px",
          border: "4px solid transparent",
          backgroundClip: "content-box",
        },
        "& *::-webkit-scrollbar-thumb:focus": {
          backgroundColor: "#959595",
        },
        "& *::-webkit-scrollbar-thumb:active": {
          backgroundColor: "#959595",
        },
        "& *::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "#959595",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: () => ({
          minHeight: "30px",
          "& button": { minHeight: "30px", minWidth: 0 },
        }),
        scrollButtons: ({ theme }) => ({
          color: theme.palette.action.active,
          display: "inline-flex",
        }),
      },
      defaultProps: {
        TabScrollButtonProps: {
          slots: {
            StartScrollButtonIcon: KeyboardArrowLeftRoundedIcon,
            EndScrollButtonIcon: KeyboardArrowRightRoundedIcon,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: ({ theme }) => ({ backgroundColor: theme.palette.tooltip.fill }),
        arrow: ({ theme }) => ({ color: theme.palette.tooltip.fill }),
      },
    },
  },
  typography: {
    h1: { fontSize: 32, fontWeight: 600, fontFamily: "Roboto", lineHeight: "120%", letterSpacing: "-0.5px" },
    h2: { fontSize: 28, fontWeight: 600, fontFamily: "Roboto", lineHeight: "120%", letterSpacing: "-0.5px" },
    h3: { fontSize: 24, fontWeight: 600, fontFamily: "Roboto", lineHeight: "120%" },
    h4: { fontSize: 20, fontWeight: 600, fontFamily: "Roboto", lineHeight: "120%", letterSpacing: "0.25px" },
    h5: { fontSize: 18, fontWeight: 600, fontFamily: "Roboto", lineHeight: "120%" },
    h6: { fontSize: 15, fontWeight: 600, fontFamily: "Roboto", lineHeight: "130%", letterSpacing: "0.15px" },
    subtitle1: {
      fontSize: 14,
      fontWeight: 500,
      fontFamily: "Roboto",
      lineHeight: "140%",
      letterSpacing: "0.15px",
    },
    subtitle2: {
      fontSize: 13,
      fontWeight: 500,
      fontFamily: "Roboto",
      lineHeight: "140%",
      letterSpacing: "0.1px",
    },
    body1: { fontSize: 13, fontWeight: 400, fontFamily: "Roboto", lineHeight: "140%", letterSpacing: "0.15px" },
    body2: { fontSize: 12, fontWeight: 400, fontFamily: "Roboto", lineHeight: "140%", letterSpacing: "0.17px" },
    caption: {
      fontSize: 12,
      fontWeight: 400,
      fontFamily: "Roboto",
      lineHeight: "120%",
      letterSpacing: "0.4px",
    },
    button: { fontSize: 13, fontWeight: 500, fontFamily: "Roboto", lineHeight: "24px" },
    overline: {
      fontSize: 12,
      fontWeight: 400,
      fontFamily: "Roboto",
      lineHeight: "120%",
      letterSpacing: "1px",
      textTransform: "uppercase",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 579,
      md: 744,
      lg: 1024,
      xl: 1600,
    },
  },
};

export const scrollerStyles = {
  overflowY: "auto",
  margin: 0,
  padding: 0,
  listStyle: "none",
  "&::-webkit-scrollbar": {
    width: "14px",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#F5F5F5",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#C7CED4",
    borderRadius: "8px",
    border: "4px solid transparent",
    backgroundClip: "content-box",
  },
};
