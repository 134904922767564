import { Box, Button, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import FileIcon from "../../../icons/FileIcon";
import { selectUserPermissions } from "../../../store/userSlice";
import { hasPermissionsForAnyCompany } from "../../helpers/accessUtils";

interface Props {
  onAddReport: () => void;
}

export function NoReports({ onAddReport }: Props) {
  const permissions = useSelector(selectUserPermissions);
  const isReadonlyMode = !hasPermissionsForAnyCompany(permissions);

  return (
    <Grid container sx={{ justifyContent: "center", flex: 1, flexDirection: "column", alignItems: "center", gap: 1 }}>
      <Box sx={{ width: 80, height: 80, display: "flex", justifyContent: "center", alignItems: "center" }}>
        <FileIcon />
      </Box>
      <Typography variant="subtitle1">Report list is empty</Typography>
      <Typography variant="body2" textAlign="center" sx={{ color: (theme) => theme.palette.text.secondary }}>
        To create your first report, click on the{" "}
        <Typography component="span" fontWeight="bold" sx={{ color: (theme) => theme.palette.text.primary }}>
          Add Report
        </Typography>{" "}
        button below
      </Typography>
      <Button
        variant="contained"
        size="medium"
        sx={(theme) => ({ width: theme.spacing(18), mt: 1.5 })}
        onClick={onAddReport}
        disabled={isReadonlyMode}
      >
        Add Report
      </Button>
    </Grid>
  );
}
