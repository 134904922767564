import React from "react";
import { getAuthToken } from "../../../auth/auth";
import { XWorksheetData } from "./useExcelDataApi.types";
import useCulturePatters from "./useCulturePatters";

export function useExcelDataApi() {
  const [data, setData] = React.useState<XWorksheetData>();
  const [dataLocal, setLocalData] = React.useState<[wsData: XWorksheetData, recentSheetName: string]>();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string>();
  const [legpSheetNames, setLegpSheetNames] = React.useState<string[]>([]);
  const [testFileSheetNames, setTestFileSheetNames] = React.useState<string[]>([]);
  const authorizationHeader = () => {
    const token = getAuthToken();
    return token ? `Bearer ${getAuthToken()}` : "";
  };
  const { getCulturePatters } = useCulturePatters();

  const load = React.useCallback(
    async (name: string) => {
      setLoading(true);
      const culturePatterns = await getCulturePatters();
      const requestBody = { PathIdx: 0, SheetName: name, CulturePatterns: culturePatterns };

      fetch(`https://entrilia51.dev:5005/ExcelData/localReport`, {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: { SelectedClientCode: "aquileia", Authorization: authorizationHeader() },
      })
        .then((result) => result.json())
        .then((result) => {
          setData(result.data);
        })
        .catch((error) => setError(error))
        .finally(() => {
          setLoading(false);
        });
    },
    [getCulturePatters]
  );

  //Developing only
  const loadSheetNamesFromLocalFile = React.useCallback((pathIdx: number, update: (sheets: string[]) => void) => {
    setLoading(true);

    fetch(`https://entrilia51.dev:5005/ExcelData/localReport/${pathIdx}`, {
      method: "GET",
      headers: { SelectedClientCode: "aquileia", Authorization: authorizationHeader() },
    })
      .then((result) => {
        if (result.ok) {
          return result.json();
        }
        throw new Error(`${result.status}`);
      })
      .then((result) => {
        update(result.data);
      })
      .catch((error) => setError(error))
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const loadSheetDataFromLocalFile = React.useCallback(
    async (pathIdx: number, sheetName: string) => {
      setLoading(true);
      const culturePatterns = await getCulturePatters();
      const requestBody = { PathIdx: pathIdx, SheetName: sheetName, CulturePatterns: culturePatterns };

      fetch(`https://entrilia51.dev:5005/ExcelData/localReportBySheetName`, {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
          SelectedClientCode: "aquileia",
          Authorization: authorizationHeader(),
        },
      })
        .then((result) => {
          if (result.ok) {
            return result.json();
          }
          throw new Error(`${result.status}`);
        })
        .then((result) => {
          setLocalData([result.data, sheetName]);
        })
        .catch((error) => setError(error))
        .finally(() => {
          setLoading(false);
        });
    },
    [getCulturePatters]
  );

  return {
    data,
    loading,
    error,
    load,
    loadSheetNamesFromLocalFile,
    legpSheetNames,
    setLegpSheetNames,
    testFileSheetNames,
    setTestFileSheetNames,
    loadSheetDataFromLocalFile,
    dataLocal,
  };
}
