import { Link as RouterLink } from "react-router";
import { SvgIconComponent } from "@mui/icons-material";
import { ListItemIcon, MenuItem } from "@mui/material";

interface Props {
  title: string;
  Icon: SvgIconComponent;
  href: string;
}

export default function MenuLinkItem({ href, title, Icon }: Props) {
  return (
    <MenuItem
      component={RouterLink}
      to={href}
      sx={(theme) => ({ color: theme.palette.primary.main, py: theme.spacing(1) })}
    >
      <ListItemIcon>
        <Icon fontSize="small" color="primary" />
      </ListItemIcon>
      {title}
    </MenuItem>
  );
}
