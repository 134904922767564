import { TokenOperationResponse } from "../api/authApi.types";
import {
  ExcelReport,
  ExcelReportParameterType,
  FilterModelType,
  ReportAccessState,
  ReportKind,
  ReportSource,
  UserData,
} from "../api/biApi.types";
import { AlertsState } from "./alertsSlice";
import { ParametersConfiguration } from "./parametersSlice";

export type RootState = {
  userInfo: UserConfiguration;
  reports: ReportsConfiguration;
  workbook: WorkbookState;
  parameters: ParametersConfiguration;
  alerts: AlertsState;
};

export type WorkbookState = {
  activeWorksheetId?: string;
  initialLoadingCompleted: boolean;
  isInEditMode: boolean;
  isExecutingExcelRun: boolean;
};

export type UserInfo = TokenOperationResponse;
export type UserConfiguration = {
  user?: UserInfo;
  userData?: UserData;
  userDataLoaded: boolean;
  selectedCompany?: string;
  lastSelectedReportListTabIdx: number;
  expandGroupsState: Record<string, Record<string, boolean>>;
  storedReportsAccessState: ReportAccessState[];
};

export type XEntriliaReportParameter = {
  id: string;
  name: string;
  values: (string | null)[];
  text?: string;
  equalityType?: FilterModelType;
  description?: string;
  reportSource: ReportSource;
  type: ExcelReportParameterType;
};

export type XEntriliaReportDescriptor = {
  id: string;
  caption: string;
  //actual range address
  address: string;
  //original range address
  originalAddress: string;
  worksheetId: string;
  worksheetName: string;
  reportCode: string;
  reportSource: ReportSource;
  reportKind: ReportKind;
  filters: XEntriliaReportParameter[];
  changedAt?: number;
  clientCode: string;
  reportType: string;
  changedBy?: string;
  withParameters: boolean;
  selectedColumns: number[] | undefined;
};

export type XEntriliaReportPrecessing = {
  id: string;
  requestCode?: string;
  loading?: boolean;
  cancellable?: boolean;
  step?: StepEnum;
  stepDescription?: string;
  error?: string;
  filters?: XEntriliaReportParameter[] | undefined;
  name: string;
};

export type XEntriliaReportDeleting = {
  id: string;
  caption: string;
};

export enum StepEnum {
  Pending = 0,
  RequestReportToGenerate = 1,
  WaitingReportToBeGenerated = 2,
  FetchGeneratedReport = 3,
  PopulateReportToWorksheet = 4,
  Done = 5,
}

export type ReportsConfiguration = {
  reports: XEntriliaReportDescriptor[];
  availableCompanies: CompanyReports[];
  trigger?: string;
  activeReport?: XEntriliaReportDescriptor;
  precessingReports: XEntriliaReportPrecessing[];
  reportsDeleting: XEntriliaReportDeleting[];
};

type CompanyReports = {
  companyCode: string;
  reports: ExcelReport[];
  areReportsLoaded: boolean;
};
