import React from "react";
import InlineLoader from "../components/inlineLoader/InlineLoader";
import { logout } from "../../auth/auth";
import { useDispatch } from "react-redux";
import { UserInfoActions } from "../../store/userSlice";
import { Navigate } from "react-router";

export default function LogoutPage() {
  const dispatch = useDispatch();
  const [loggedOut, setLoggedOut] = React.useState(false);

  React.useEffect(() => {
    logout();
    dispatch(UserInfoActions.updateUser(undefined));
    setLoggedOut(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loggedOut) return <Navigate to={"/"} />;

  return <InlineLoader text="Logging out..." />;
}
