import { useCallback } from "react";
import { logError } from "../../../logging";
import { getExcelDateFormatFromLocale } from "../../../utils/Utilities";

export interface CulturePatterns {
  shortDatePattern: string;
  longTimePattern: string;
  numberDecimalSeparator: string;
  numberGroupSeparator: string;
  userDefaultDateFormat: string;
}

const shortDatePattern = "datetimeFormat/shortDatePattern";
const longTimePattern = "datetimeFormat/longTimePattern";
const numberDecimalSeparator = "numberFormat/numberDecimalSeparator";
const numberGroupSeparator = "numberFormat/numberGroupSeparator";
const payload = [shortDatePattern, longTimePattern, numberDecimalSeparator, numberGroupSeparator].join(",");

const useCulturePatters = () => {
  const getCulturePatters = useCallback(async (): Promise<CulturePatterns | undefined> => {
    return Excel.run(async (context) => {
      try {
        context.load(context.application.cultureInfo, payload);
        await context.sync();

        return {
          shortDatePattern: context.application.cultureInfo.datetimeFormat.shortDatePattern,
          longTimePattern: context.application.cultureInfo.datetimeFormat.longTimePattern,
          numberDecimalSeparator: context.application.cultureInfo.numberFormat.numberDecimalSeparator,
          numberGroupSeparator: context.application.cultureInfo.numberFormat.numberGroupSeparator,
          userDefaultDateFormat: getExcelDateFormatFromLocale(Office.context.contentLanguage),
        };
      } catch (error) {
        logError(error, "Error loading culture patterns");
        return undefined;
      }
    });
  }, []);

  return { getCulturePatters };
};

export default useCulturePatters;
