import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import reportsReducer from "./reportsSlice";
import workbookStateReducer from "./workbookSlice";
import parametersReducer from "./parametersSlice";
import alertsReducer from "./alertsSlice";

export const store = configureStore({
  reducer: {
    userInfo: userReducer,
    reports: reportsReducer,
    workbook: workbookStateReducer,
    parameters: parametersReducer,
    alerts: alertsReducer,
  },
});
