import { XCell, XCellBorders, XCellStyle, XFill, XFontStyle, XNumberFormat } from "../hooks/useExcelDataApi.types";

export interface CustomFormatCheckResult {
  format: string;
  isFormatValid: boolean;
  shouldBeInsertedInLocal: boolean;
}

export const getValidNumberFormatAsync = async (
  cell: Excel.Range,
  context: Excel.RequestContext,
  numberFormat: XNumberFormat | undefined,
  checkedFormats: CustomFormatCheckResult[]
) => {
  let shouldBeInsertedInLocal = false;
  let result = await applyFormat(cell, numberFormat?.customFormat, checkedFormats, context, shouldBeInsertedInLocal);

  if (result === undefined) {
    shouldBeInsertedInLocal = numberFormat?.defaultFormat !== "General";
    result = await applyFormat(cell, numberFormat?.defaultFormat, checkedFormats, context, shouldBeInsertedInLocal);
  }

  return result ?? "";
};

const applyFormat = async (
  cell: Excel.Range,
  format: string | undefined,
  checkedFormats: CustomFormatCheckResult[],
  context: Excel.RequestContext,
  shouldBeInsertedInLocal: boolean
) => {
  if (!format) {
    return undefined;
  }

  const checkResult = checkedFormats.find((f) => f.format === format);
  if (checkResult) {
    return checkResult.isFormatValid ? checkResult.format : undefined;
  }
  setNumberFormatSafe(cell, format, shouldBeInsertedInLocal);
  const { isCustomNumberFormatsCapacityLimitReached: isLimitReached } = await trySyncCustomNumberFormatAsync(context);
  checkedFormats.push({ format, isFormatValid: isLimitReached === false, shouldBeInsertedInLocal });

  return isLimitReached ? undefined : format;
};

const trySyncCustomNumberFormatAsync = async (context: Excel.RequestContext) => {
  try {
    await context.sync();
    return { isCustomNumberFormatsCapacityLimitReached: false };
  } catch (error) {
    if (isCustomNumberFormatLimitReachedError(error)) {
      return { isCustomNumberFormatsCapacityLimitReached: true };
    }
    throw error;
  }
};

const isCustomNumberFormatLimitReachedError = (error: unknown) => {
  return (
    error instanceof OfficeExtension.Error &&
    error.code === Excel.ErrorCodes.invalidArgument &&
    (error.debugInfo.errorLocation === "Range.numberFormat" ||
      error.debugInfo.errorLocation === "Range.numberFormatLocal")
  );
};

export const setNumberFormatSafe = (cell: Excel.Range, format: string, shouldBeInsertedInLocal: boolean) => {
  if (shouldBeInsertedInLocal) {
    cell.numberFormatLocal = [[format]];
    return;
  }
  cell.numberFormat = [[format]];
};

export const setValues = (cell: Excel.Range, cellData: XCell) => {
  if (cellData.dataType === Excel.CellValueType.string) {
    cell.valuesAsJson = [
      [
        {
          type: Excel.CellValueType.string,
          basicValue: cellData.value ?? "",
        },
      ],
    ];
    return;
  }
  if (cellData.dataType === Excel.CellValueType.double) {
    cell.values = [[cellData.value]];
    return;
  }
};

export const setFormulas = (cell: Excel.Range, cellData: XCell) => {
  if (cellData.formula) {
    cell.formulas = [[`=${cellData.formula}`]];
  }
};

export const setCommonFormatOptions = (format: Excel.CellPropertiesFormat, styles?: XCellStyle) => {
  if (styles?.alignment?.wrapText !== undefined) {
    format.wrapText = styles.alignment.wrapText;
  }
  if (styles?.alignment?.horizontal !== undefined) {
    format.horizontalAlignment = styles.alignment.horizontal;
  }
  if (styles?.alignment?.vertical !== undefined) {
    format.verticalAlignment = styles.alignment.vertical;
  }
  if (styles?.alignment?.indent !== undefined) {
    format.indentLevel = styles.alignment.indent;
  }
};

export const setFill = (format?: Excel.CellPropertiesFormat, fill?: XFill) => {
  if (!format || !fill) {
    return;
  }
  if (fill.patternType !== undefined) {
    (format.fill ??= {}).pattern = fill.patternType;
  }
  if (fill.foregroundColor !== undefined) {
    (format.fill ??= {}).color = fill.foregroundColor;
  }
  if (fill.backgroundColor !== undefined) {
    (format.fill ??= {}).patternColor = fill.backgroundColor;
  }
};

export const setFont = (format?: Excel.CellPropertiesFormat, font?: XFontStyle) => {
  if (!format || !font) {
    return;
  }
  if (font.bold !== undefined) {
    (format.font ??= {}).bold = font.bold;
  }
  if (font.color !== undefined) {
    (format.font ??= {}).color = font.color;
  }
  if (font.italic !== undefined) {
    (format.font ??= {}).italic = font.italic;
  }
  if (font.name !== undefined) {
    (format.font ??= {}).name = font.name;
  }
  if (font.size !== undefined) {
    (format.font ??= {}).size = font.size;
  }
  if (font.strike !== undefined) {
    (format.font ??= {}).strikethrough = font.strike;
  }
  if (font.underline !== undefined) {
    (format.font ??= {}).underline = font.underline;
  }
};

export const setBorder = (format?: Excel.CellPropertiesFormat, borders?: XCellBorders) => {
  if (!format || !borders) {
    return;
  }
  if (borders.bottom !== undefined) {
    (format.borders ??= {}).bottom = {
      style: borders.bottom.style,
      color: borders.bottom.color,
      weight: borders.bottom.weight,
    };
  }
  if (borders.left !== undefined) {
    (format.borders ??= {}).left = {
      style: borders.left.style,
      color: borders.left.color,
      weight: borders.left.weight,
    };
  }
  if (borders.right !== undefined) {
    (format.borders ??= {}).right = {
      style: borders.right.style,
      color: borders.right.color,
      weight: borders.right.weight,
    };
  }
  if (borders.top !== undefined) {
    (format.borders ??= {}).top = {
      style: borders.top.style,
      color: borders.top.color,
      weight: borders.top.weight,
    };
  }
};
