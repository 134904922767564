import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store.types";

type AlertId = "CustomNumberFormatsCapacityLimitReached";

interface AlertItem {
  id: AlertId;
  display: boolean;
  wasClosedManually: boolean;
}

export interface AlertsState {
  items: Record<AlertId, AlertItem>;
}

const initialState: AlertsState = {
  items: {
    CustomNumberFormatsCapacityLimitReached: {
      id: "CustomNumberFormatsCapacityLimitReached",
      display: false,
      wasClosedManually: false,
    },
  },
};

const alertsSlice = createSlice({
  name: "alerts",
  initialState,
  reducers: {
    showAlert(state, action: PayloadAction<AlertId>) {
      if (state.items[action.payload].wasClosedManually === false) {
        state.items[action.payload].display = true;
      }
    },
    hideAlert(state, action: PayloadAction<AlertId>) {
      state.items[action.payload].display = false;
      state.items[action.payload].wasClosedManually = true;
    },
  },
});

export const { showAlert, hideAlert } = alertsSlice.actions;

export const selectShouldShowCustomNumberFormatsCapacityLimitReached = (state: RootState) =>
  state.alerts.items.CustomNumberFormatsCapacityLimitReached.display;

export default alertsSlice.reducer;
