import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import { IconButton } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useReportServiceContext } from "../../contexts/ReportServiceContext";
import { addReportToDeleting, removeReportFromDeleting, updateTrigger } from "../../store/reportsSlice";
import { XEntriliaReportDescriptor, XEntriliaReportPrecessing } from "../../store/store.types";
import { deleteShapes, deleteTables, removeGroups, removeNamedRange } from "../components/DevTools";
import useExcelRunExecution from "../components/hooks/useExcelRunExecution";
import { getStoredReportSettings, storeReportSettings } from "../components/utils/storedReportSettings";
import WorkbookReportItemContentMenu from "./WorkbookReportItemContentMenu";
import { UserInfoActions } from "../../store/userSlice";

interface Props {
  report: XEntriliaReportDescriptor;
  processingReport: XEntriliaReportPrecessing | undefined;
  isReadonly: boolean;
  isDeleted: boolean;
}

const WorkbookReportItemContentMoreOption = ({ report, isDeleted, isReadonly, processingReport }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const { excelRun } = useExcelRunExecution();
  const dispatch = useDispatch();
  const { refreshReport } = useReportServiceContext();

  const handleDeleteReport = React.useCallback(async (activeReport: XEntriliaReportDescriptor) => {
    if (activeReport?.worksheetId === undefined) {
      return;
    }
    await excelRun(async (context) => {
      dispatch(addReportToDeleting(activeReport));
      await removeNamedRange(context, activeReport.worksheetId);
      await removeReportSettings(context, activeReport.id);
      const sheet = context.workbook.worksheets.getItem(activeReport.worksheetId);
      if (sheet !== undefined) {
        const range = sheet.getRange(activeReport.address);
        await deleteTables(sheet, context);
        await deleteShapes(sheet, context);
        range.unmerge();
        removeGroups(range);
        range.clear("All");
      }
      dispatch(updateTrigger());
    });
    dispatch(removeReportFromDeleting(activeReport.id));
    dispatch(UserInfoActions.deleteAccessibleReport({ storedReportId: activeReport.id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <IconButton
        size="small"
        sx={(theme) => ({ p: 0.4, color: theme.palette.secondary.light })}
        onClick={(evt) => {
          setAnchorEl(evt.currentTarget);
          evt.stopPropagation();
        }}
        disabled={isReadonly}
      >
        <MoreVertRoundedIcon fontSize="small" />
      </IconButton>
      {anchorEl !== null && (
        <WorkbookReportItemContentMenu
          anchorEl={anchorEl}
          activeReport={report}
          handleRefreshReport={refreshReport}
          handleDeleteReport={handleDeleteReport}
          onClose={() => setAnchorEl(null)}
          isReportDeleted={isDeleted}
          processingReport={processingReport}
        />
      )}
    </>
  );
};

export default WorkbookReportItemContentMoreOption;

async function removeReportSettings(context: Excel.RequestContext, reportId: string) {
  const storedReportSettings = await getStoredReportSettings(context);
  await storeReportSettings(
    context,
    storedReportSettings.filter((s) => s.id !== reportId)
  );
}
